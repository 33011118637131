.our-clients-container {
	min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  /* background-image: url('./graphics/lightbulb.svg'); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: 40%; */
  /* background-color: rgb(33, 37, 41); */
  /* font-family: sans-serif; */
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.all-clients-container {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 10em; */
  background-color: white;
  width: 100%;
  border-bottom: 1em solid black;
  /* padding-bottom: 2em; */
  margin-bottom: 3em;
}

.client-container {
	width: 150px;
	/* flex-direction: row; */
}

.client-container img {
  max-width: 100%; /* Ensures the image scales within the container */
  height: auto;
}

.clients-text-container, .testimonials-container {
  /* margin: 4em 0em; */
  width: 70%;
  /* width: 70%; */
}

.testimonials-container {
  margin: 2em 0em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonials-img {
  height: 10em;
}

.testimonials-text {
  display: flex;
  flex-direction: row;
}

.highlight-green {
	color: #28A745;
}
.highlight-gold {
	color: #FFD700;
}
.highlight-blue {
	color: #61DAFB;
}
.text {
  margin: 0.5em 0em;
}

@keyframes colourChange {
  0% {
    color: #61DAFB; /* Red */
  }
  33% {
    color: #28A745; /* Green */
  }
  66% {
    color: #FFD700; /* Blue */
  }
  100% {
    color: #61DAFB;; /* Back to Red */
  }
}

.colour-change {
  animation: colourChange 30s infinite;
}

.button-like {
  padding: 10px 20px;
  border: 2px solid rgb(99, 102, 105);
  border-radius: 5px;
  background-color: rgb(99, 102, 105);
  color: white;
}

/* mobile device media queries */
@media only screen and (max-width: 459px) {
	.all-clients-container {
    /* display: flex; */
    flex-direction: column;
    gap: 2em;
    padding: 1em 0em;
  }

  .clients-text-container, .testimonials-container {
    width: 90%;
  }
}

@media only screen and (min-width: 460px) and (max-width: 735px) {
	.all-clients-container {
    /* display: flex; */
    /* justify-content: space-evenly; */
    /* flex-direction: column; */
    gap: 2em;
    padding: 1em 0em;
  }

  .clients-text-container, .testimonials-container {
    width: 90%;
  }
}