.background-graphic {
	background-image: url('../../public/graphics/background.svg');
	background-size: 45%;
  background-position-x: center;
	background-position-y: 70%;
	/* background-position: center; */
  background-repeat: no-repeat;
	/* z-index: -1; */
	position: absolute; /* Positioning it to cover the area */
  top: 0;
  /* left: -1em; */
  width: 100%;
  height: 100%;
	opacity: 0.5;
}

/* .background-graphic {
	animation: pulse 5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
} */

.landing-content-container {
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	/* padding: 0px 12px; */
	min-height: 100vh;
	background-color: rgb(33, 37, 41);
}

.landing-text-container, .benefits-container {
	width: 80%;
	/* flex: 1; */
	/* margin-top: 10%; */
	/* padding: 2em; */
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	/* height: 100vh; */
	/* padding: 10px 12px; */
}

.landing-text-container.benefits {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.benefits-container {
	display: flex;
	flex-direction: row;
	margin-top: 0.5em;
	
}

.waiting-list-button-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 2.5em 0em;
	z-index: 1;
}

.landing-text-container {
	color: white;
}

.highlight-green {
	color: #28A745;
}
.highlight-gold {
	color: #FFD700;
}
.highlight-blue {
	color: #61DAFB;
}

.benefit-svg {
  height: 10vmin;
  margin-bottom: 15px;
}

.corner-graphic {
	background-image: url('../../public/graphics/corner.svg');
	/* background-size: fit; */
	background-repeat: no-repeat;
	position: absolute;
	width: 50vw;
	height: 50vh;
/* z-index: -1; */
	/* opacity: 0.5; */
}

.arrows {
	height: 10vmin;
	/* width: fit-content; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* opacity: 0.5; */

  /* margin-bottom: 15px; */
}

.right-arrow, .left-arrow {
	width: 10vmin;
}

/* mobile device media queries */
@media only screen and (max-width: 557px) {
	.landing-content-container {
		min-height: 100vh;
		max-height: none;
		justify-content: start;
	}

	.landing-text-container, .benefits-container {
		width: 90%;
	}

	.waiting-list-button-container {
		margin: 0;
	}

	.landing-text-container {
		padding-top: 2em;
	}

	.benefits-container {
		display: flex;
		flex-direction: column;
		margin: 0.5em 0em 1em 0em;
	}

	.benefit-svg, .invisible-in-mobile {
		display: none;
	}
}


/* media screens bigger than a phone */
@media only screen and (min-width: 558px) and (max-width: 838px) {
	.landing-content-container {
		height: 100vh;
		justify-content: start;
	}

	.landing-text-container, .benefits-container {
		width: 90%;
	}

	.waiting-list-button-container {
		margin: 0;
	}

	.landing-text-container {
		padding-top: 2em;
	}

	.benefit-svg, .invisible-in-mobile {
		display: none;
	}
}

@media only screen and (min-width: 839px) and (max-width: 943px) {
	.landing-content-container {
		height: 100vh;
		justify-content: center;
	}

	.landing-text-container, .benefits-container {
		width: 90%;
	}

	.landing-text-container {
		padding-top: 2em;
	}

	.waiting-list-button-container {
		margin: 0;
	}
}