.what-we-do-container {
	background-color: rgb(33, 37, 41);
	display: flex;
	flex-direction: column; /* Change to column to stack content vertically */
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.what-we-do-text-container {
	color: white;
	/* width: 30%; */
	padding: 2em 0em;
}

.item-container {
	width: 15em;
}

.what-we-do-svg {
	height: 20%;
}

.what-we-do-details-container {
	width: 70%;
	display: flex;
	flex-direction: row; /* Maintain row layout for card items */
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 1em;
}

.title-with-lines {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-bottom: 1em;
}

.waiting-list-button-container {
	text-align: center;
}

/* mobile device media queries */
@media only screen and (max-width: 1000px) {
	.what-we-do-container {
		padding: 2em;
	}

	.what-we-do-text-container, .what-we-do-details-container {
		width: 100%;
	}

	.what-we-do-text-container {
		padding-bottom: 2em;
	}

	.waiting-list-button-container {
		padding: 2em 0em;
	}

	.item-container {
		width: 10em;
	}
}

@media only screen and (min-width: 501px) and (max-width: 1000px) {

}
