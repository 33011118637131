.logo-container {
	display: flex;
	align-items: center;
}

.logo-name {
	margin: 0;
	color: #61DAFB;
	font-family: 'Courier New', Courier, monospace;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.header-items-container {
	color: whitesmoke;
	display: flex;
	gap: 20px;
}

.header-items-container .nav-link {
  color: whitesmoke;
	padding: 5px;
	transition: transform 0.3s ease, color 0.3s ease;
	transform-origin: center;
}

.header-items-container .nav-link:hover {
  transform: scale(1.1);
  color: #61DAFB;
}

#sidepanel-container {
	position: relative;
	margin: 1em;
	display: none;
}

@media (max-width: 768px) {
	.header-items-container {
		display: none;
	}

	#sidepanel-container {
		display: block;
		column-gap: 20px;
	}

	.bm-burger-button {
		display: block;
	} 
}

#sidepanel-container .nav-link:active {
  color: #61DAFB;
}

/* Position and sizing of burger button */
.bm-burger-button {
  width: 2em;
  height: 2em;
} 

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #61DAFB;
}

.bm-burger-button:visited {
	transform: scale(1.1);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(33, 37, 41);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgb(33, 37, 41);
}

/* Wrapper for item list */
.bm-item-list {
  color: whitesmoke;
	padding: 1em;
}

/* Individual item */

.bm-item {
	margin-bottom: 2em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}